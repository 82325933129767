<template>
  <v-container fluid>
    <h2>系統日誌</h2>
    <div class="d-flex mt-7 justify-space-between">
      <div class="d-flex">
        <v-text-field
          class="mr-2 inputWhiteBg searchField"
          v-model="searchText"
          outlined
          dense
          placeholder="搜尋動作"
        ></v-text-field>
        <v-btn class="mr-7" @click="getList" color="primary">搜尋</v-btn>
      </div>
      <div>
        <material-date-picker
          :date="date"
          class="inputWhiteBg"
          label="選擇時間"
          @dateCange="dateCange"
          @update="getList"
          range
        ></material-date-picker>
      </div>
    </div>

    <material-table
      class="evelatioin-1"
      :headers="header"
      :items="items"
      :options.sync="pageMeta.options"
      :items-per-page="-1"
      hide-default-footer
    >
      <template #createdAt="{ item }">
        {{ dateFormate(item.createdAt) }}
      </template>
      <!-- <template #actions="{ item }">
        <div class="text-no-wrap">
          <v-icon @click="edit">mdi-pencil</v-icon>
        </div>
      </template> -->
    </material-table>
    <material-pagination
      :totalPages="pageMeta.totalPages"
      @pageFilter="pageFilter"
    >
    </material-pagination>
  </v-container>
</template>
<script>
import { apiGetAudit } from "../../api/settings";
import { searchMixin, versionMixin, dateFormateMixin } from "../../helper";

import MaterialDatePicker from "../../components/MaterialDatePicker.vue";
import MaterialTable from "../../components/MaterialTable.vue";
export default {
  components: { MaterialTable },
  mixins: [searchMixin, dateFormateMixin],
  data() {
    return {
      MaterialDatePickerearchText: null,
      header: [
        { text: "動作", value: "path", sortable: false },
        { text: "帳號", value: "username", sortable: false },
        { text: "名稱", value: "name", sortable: false },
        { text: "IP", value: "ip", sortable: false },
        { text: "動作時間", value: "createdAt" },
        // { text: "操作", value: "actions"},
      ],
      items: [
        // {
        //   username: "devon.lane",
        //   name: "Wade Warren",
        //   ip: "199.32.94.112",
        //   role: "稽核人員",
        //   createdAt: "2022-06-02 11:23:20",
        //   path: "編輯風險評估",
        //   page: "風險評估"
        // },
      ],
      // displayItems: [],
      searchText: "",
      options: {
        sortBy: ["createdAt"],
        sortDesc: [true],
      },
      pageMeta: {
        options: {
          sortBy: ["createdAt"],
          sortDesc: [true],
        },
        page: 1,
        totalPages: 1,
        itemsPerPage: 10,
      },
      // totalPages: 1,
      date: [],
    };
  },
  mounted() {
    console.log("here");
    this.getList();
  },
  watch: {
    "pageMeta.options": {
      handler() {
        this.getList();
      },
    },
  },
  methods: {
    // updateDisplayItems(){
    //   this.displayItems = [...this.items]
    // },
    /* Datepicker */
    dateCange(val) {
      this.date = val.sort();
    },
    // 分頁搜尋
    pageFilter() {
      console.log("pageFilter");
      let pageParams = arguments[0];
      console.log(pageParams);
      this.pageMeta.page = pageParams.page;
      this.pageMeta.itemsPerPage = pageParams.itemsPerPage;
      this.getList();
    },
    edit() {
      console.log("In edit");
    },
    async getList() {
      try {
        let filterData = {};
        if (this.date != null && this.date.length == 2)
          filterData = {
            start: this.date[0],
            end: this.date[1],
          };
        if (this.searchText != "") {
          filterData["keyword"] = this.searchText;
        }
        // let searchData = this.makeUrlData();
        let searchData = this.combineQueries(this.pageMeta, filterData);
        // console.log("searchData: ", searchData)
        let res = await apiGetAudit(searchData);
        // console.log("getAudit data: ", res.data)
        this.items = res.data.content;
        this.pageMeta.totalPages = res.data.totalPages;
        // this.updateDisplayItems()
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.searchField {
  max-width: 300px;
}
.inputWhiteBg {
  background-color: #fff;
}
</style>
